import React, { Component } from "react"
import Layout from "../components/layout"
import VideoSelect from "../components/VideoSelect.js"
import ReactPlayer from "react-player"
import VideoList from "../data/videos"

class OldSchool extends Component {
  constructor(props) {
    super(props)

    let initialVideo = VideoList[0].videos[0]

    this.videoChange = this.videoChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.state = {
      videoLink: initialVideo.link,
      guideContent: initialVideo.guide,
      title: initialVideo.title,
      vGroupId: 0,
      videos: VideoList[0].videos,
      menuToggle: false
    }
    this.videoRef = React.createRef()
  }

  videoChange(video, group) {
    this.setState({
      videoLink: video.link,
      guideContent: video.guide,
      title: video.title,
      vGroupId: group.id
    })
    this.setState({ videos: group.videos })
    this.forceUpdate()
    if (typeof window !== 'undefined') {
      window.scrollTo(0, this.videoRef.current.offsetTop)
    }
  }

  handleClickOutside(e) {
    // Don't hide menu if the element selected is in the tree menu
    if (e.target.parentElement.parentElement.id === 'tree' || e.target.parentElement.parentElement.id === 'tree-children') {
      return
    }
    if (this.state.menuToggle) {
      this.setState({ menuToggle: false })
    }
  }

  handleClick() {
    this.setState({ menuToggle: !this.state.menuToggle })
  }

  render() {
    return (
      <div onClick={ this.handleClickOutside }>
        <Layout handleMenuClick={ this.handleClick }>
          <div className={`lg:flex xl:flex ${ !this.state.menuToggle ? '' : 'overflow-hidden' } max-h-screen`}>
            <div className={`lg:w-2/12 xl:w-2/12 sm:absolute xs:absolute lg:static xl:static sm:h-full xs:h-full lg:h-auto xl:h-auto select-bar menu ${ !this.state.menuToggle ? 'menu-hidden' : 'menu-visible' }`}>
              <h1 className="heading-training-videos">Training Videos</h1>
              <VideoSelect action={this.videoChange}/>
            </div>
            <div className="lg:w-10/12 xl:w-10/12 sm:w-full xs:w-full">
              <div className="p-3 flex sm:flex-wrap xs:flex-wrap xl:flex-no-wrap lg:flex-no-wrap bg-main">
                <div className="lg:w-9/12 xl:w-9/12 sm:w-full xs:w-full sm:ml-2 xs:ml-2 lg:mr-2 xl:mr-2">
                  <h1 className="heading">{ this.state.title }</h1>
                  <div className="p-3 bg-white">
                    <ReactPlayer
                      ref={this.videoRef}
                      url={ this.state.videoLink }
                      controls={true}
                      playing={true}
                      width="100%"
                      height="600px"
                    />
                  </div>
                </div>
                <div className="lg:w-3/12 xl:w-3/12 sm:w-full xs:w-full ml-2">
                  <h1 className="heading">Step by step guide</h1>
                  <div className="p-3 bg-white sm:max-h-600 sm:min-h-200 xs:max-h-600 xs:min-h-200 lg:h-full xl:h-full overflow-auto guide-body">
                    <div dangerouslySetInnerHTML={{ __html: this.state.guideContent }}></div>
                  </div>
                </div>
              </div>
              <div className="w-full bg-main xl:block lg:block md:hidden">
                <h1 className="heading">{ VideoList[this.state.vGroupId].title }</h1>
                <div className="bg-white flex xs:flex-col sm:flex-col lg:flex-row xl:flex-row max-w-full overflow-auto px-1">
                  { this.state.videos.map(video => (
                    <div className="my-5 h-48" onClick={() => this.videoChange(video, VideoList[this.state.vGroupId]) }>
                      <div className="bg-oldblue py-16 px-8 mx-2 play-button relative h-full">
                        <h3 className="text-white text-center font-bold w-266">
                          { video.title }
                        </h3>
                      </div>
                    </div>
                  )) }
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default OldSchool
