import React, { Component } from "react"
import videoList from "../data/videos.js"
import '../styles/scss/tree.scss'

class VideoSelect extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(evt) {
    if (evt.target.parentElement.classList.contains("closed")) {
      evt.target.parentElement.classList.remove("closed")
    } else {
      evt.target.parentElement.classList.add("closed")
    }
  }

  render() {
    return (
      <ul id="tree" className="tree-ul lg:max-h-full xl:max-h-full lg:min-h-full xl:min-h-full sm:max-h-85 xs:max-h-85 overflow-scroll">
        { videoList.map((videoItem, index) => (
          <li className={`default node ${ index !== 0 ? 'closed' : '' } ${ index + 1 === videoList.length ? 'tree-last' : '' }`} onClick={this.handleClick}>
            <span className="icon open-close-icon"></span>
            <a href="#" className="tree-anchor">
              <i className="icon folder"></i>
              { videoItem.title }
            </a>
            <ul id="tree-children" className="tree-children">
              { videoItem.videos.map((video, index) => (
                <li
                  className={`leaf-node default ${ index + 1 === videoItem.videos.length ? 'tree-last' : '' }`}
                  onClick={() => this.props.action(video, videoItem)}
                >
                  <span className="icon leaf-line-icon"></span>
                  <a href="#" className="tree-anchor">
                    { video.title }
                  </a>
                </li>
              )) }
            </ul>
          </li>
        )) }
      </ul>
    )
  }
}

export default VideoSelect
